import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import DLProdSamplePdf from '../../../content/assets/pdfs/DLProd-Sample.pdf';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Accordion from '../../components/ui/Accordion/Accordion';
import Block from '../../components/ui/Cards/Block';
import Card from '../../components/ui/Cards/Card';
import Seo from '../../components/utils/Seo';
import { useAnimationOnScroll } from '../../components/utils/useAnimateOnScroll';
import { Direction } from '../../globals';
import './deep-learning-in-production-book.scss';

interface DeepLearningProductionProps {
    data: {
        deepLearningProductionYaml: {
            faq: QuestionAnswer[];
            image: FluidImage;
            image_amazon: FluidImage;
            image_leanpub: FluidImage;
        };
    };
}
const DeepLearningProduction: React.FC<DeepLearningProductionProps> = ({
    data
}: DeepLearningProductionProps) => {
    useAnimationOnScroll();

    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Deep Learning in Production Book by Sergios Karagiannakos"
                url="deep-learning-in-production-book"
                description="Deep Learning in Production book by Sergios Karagiannakos"
                image={data.deepLearningProductionYaml.image.childImageSharp.fluid.src}

            />
            <div className="deep-learning-production-banner">
                <div className="deep-learning-production-banner__content">
                    <div className="deep-learning-production-banner__content__details">
                        <h1 className="deep-learning-production-banner__title">
                            DEEP LEARNING IN PRODUCTION BOOK
                        </h1>
                        <h2>
                            Design, develop, train, deploy, scale and maintain
                            your Machine Learning models
                        </h2>
                        <Group
                            size={3}
                            className="deep-learning-production-banner__buttons"
                        >
                            <Card
                                className="deep-learning-production__card--banner"
                                direction={Direction.vertical}
                                onExternalClickLink="https://amzn.to/3oa50Vj"
                            >
                                <p className="deep-learning-production__card--banner__text">
                                    Paberback
                                </p>
                                <p>Buy for $30</p> 
                               

                                <Img
                                    fluid={
                                        data.deepLearningProductionYaml
                                            .image_amazon.childImageSharp.fluid
                                    }
                                    className="deep-learning-production__card--banner__image"
                                    alt="Amazon logo"
                                ></Img>
                            </Card>
                            <Card
                                className="deep-learning-production__card--banner"
                                direction={Direction.vertical}
                                onExternalClickLink="https://amzn.to/3yvjQJy"
                            >
                                <p className="deep-learning-production__card--banner__text">
                                    Kindle
                                </p>
                                <p>Buy for $26</p> 

                                <Img
                                    fluid={
                                        data.deepLearningProductionYaml
                                            .image_amazon.childImageSharp.fluid
                                    }
                                    className="deep-learning-production__card--banner__image"
                                    alt="Amazon logo"
                                ></Img>
                            </Card>
                            <Card
                                className="deep-learning-production__card--banner"
                                direction={Direction.vertical}
                                onExternalClickLink="https://leanpub.com/DLProd"
                            >
                                <p className="deep-learning-production__card--banner__text">
                                    Ebook/Pdf
                                </p>
                                 <p>Set the price  </p> 

                                <Img
                                    fluid={
                                        data.deepLearningProductionYaml
                                            .image_leanpub.childImageSharp.fluid
                                    }
                                    className="deep-learning-production__card--banner__image"
                                    alt="leanpub logo"
                                ></Img>
                            </Card>
                        </Group>
                    </div>
                    <div className="deep-learning-production-banner__content__image">
                        <Img
                            fluid={
                                data.deepLearningProductionYaml.image
                                    .childImageSharp.fluid
                            }
                            alt="Deep Learning in Production book"
                        ></Img>
                    </div>
                </div>
            </div>

            <PageSection className="deep-learning-production-section">
                <Container>
                    <h2>Become a Machine Learning Engineer</h2>
                    <p>
                        Machine Learning gradually transforms more and more
                        industries. Tech and non-tech companies are starting to
                        incorporate AI into their business to outgrow their
                        competition. Thus, machine learning engineering is one
                        of the more sought after and well paid skills of today.
                    </p>
                    <p>
                        A Machine Learning Engineer can be described as a role
                        that bridges the gap between data science and IT. A ML
                        Engineer is much more than a guy who builds ML models.
                        Depending on the company, his/her responsibilities also
                        include:
                    </p>
                    <Group size={2}>
                        <Block
                            direction={Direction.vertical}
                            className="deep-learning-production-section__bullet"
                            aos="zoom-in"
                        >
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 42.67"
                                    width="32"
                                    height="32"
                                    fill="#f4a444"
                                >
                                    <g>
                                        <g>
                                            {/*eslint-disable-next-line max-len*/}
                                            <path d="M63.22,19.57,44.5.78a2.67,2.67,0,0,0-3.78,3.77L54.79,18.67H2.67a2.67,2.67,0,1,0,0,5.33H55L40.73,38.11a2.67,2.67,0,1,0,3.76,3.78L63.21,23.35a2.67,2.67,0,0,0,0-3.78Z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h3 className="deep-learning-production-section__bullet__text">
                                Designing the entire ML lifecycle
                            </h3>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="deep-learning-production-section__bullet"
                            aos="zoom-in"
                        >
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 42.67"
                                    width="32"
                                    height="32"
                                    fill="#f4a444"
                                >
                                    <g>
                                        <g>
                                            {/*eslint-disable-next-line max-len*/}
                                            <path d="M63.22,19.57,44.5.78a2.67,2.67,0,0,0-3.78,3.77L54.79,18.67H2.67a2.67,2.67,0,1,0,0,5.33H55L40.73,38.11a2.67,2.67,0,1,0,3.76,3.78L63.21,23.35a2.67,2.67,0,0,0,0-3.78Z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h3 className="deep-learning-production-section__bullet__text">
                                Developing data processing pipeline
                            </h3>
                        </Block>
                    </Group>
                    <Group size={2}>
                        <Block
                            direction={Direction.vertical}
                            className="deep-learning-production-section__bullet"
                            aos="zoom-in"
                        >
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 42.67"
                                    width="32"
                                    height="32"
                                    fill="#f4a444"
                                >
                                    <g>
                                        <g>
                                            {/*eslint-disable-next-line max-len*/}
                                            <path d="M63.22,19.57,44.5.78a2.67,2.67,0,0,0-3.78,3.77L54.79,18.67H2.67a2.67,2.67,0,1,0,0,5.33H55L40.73,38.11a2.67,2.67,0,1,0,3.76,3.78L63.21,23.35a2.67,2.67,0,0,0,0-3.78Z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h3 className="deep-learning-production-section__bullet__text">
                                Training the models in cloud clusters
                            </h3>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="deep-learning-production-section__bullet"
                            aos="zoom-in"
                        >
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 42.67"
                                    width="32"
                                    height="32"
                                    fill="#f4a444"
                                >
                                    <g>
                                        <g>
                                            {/*eslint-disable-next-line max-len*/}
                                            <path d="M63.22,19.57,44.5.78a2.67,2.67,0,0,0-3.78,3.77L54.79,18.67H2.67a2.67,2.67,0,1,0,0,5.33H55L40.73,38.11a2.67,2.67,0,1,0,3.76,3.78L63.21,23.35a2.67,2.67,0,0,0,0-3.78Z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h3 className="deep-learning-production-section__bullet__text">
                                Deploying and maintaining large scale ML systems
                            </h3>
                        </Block>
                    </Group>

                    <p>
                        It is estimated that 50% of IT leaders will struggle to
                        move their AI projects past proof of concept (POC) to a
                        production level of maturity. This is where you come
                        into play.
                    </p>
                    <p>
                        After reading this book, you will be ready to build a
                        fully-functional Deep Learning application. The
                        background and skills that you will acquire from this
                        book will provide you better job opportunities, will
                        differentiate you from other data scientists and machine
                        learning researchers and the most important thing: they
                        will make you a better and more well-rounded engineer.
                    </p>
                    <h2>What will you learn?</h2>

                    <Group size={2}>
                        <Block direction={Direction.vertical} aos="zoom-in">
                            <Img
                                fluid={
                                    data.deepLearningProductionYaml.image
                                        .childImageSharp.fluid
                                }
                                alt="Deep Learning in Production book"
                            ></Img>
                        </Block>
                        <Block
                            className="deep-learning-production-section"
                            direction={Direction.vertical}
                            aos="zoom-in"
                        >
                            <div className="deep-learning-production-section__bullet">
                                <span>
                                    <svg
                                        width="32px"
                                        height="32px"
                                        viewBox="0 0 18 18"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g stroke="none" fill="none">
                                            <g transform="translate(-103.000000, -4323.000000)">
                                                <g transform="translate(100.000000, 4266.000000)">
                                                    <g transform="translate(0.000000, 54.000000)">
                                                        <g>
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path
                                                                // eslint-disable-next-line max-len
                                                                d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                                fill="#f4a444"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <h3 className="deep-learning-production-section__bullet__text">
                                    how to design a deep learning system from
                                    scratch
                                </h3>
                            </div>
                            <div className="deep-learning-production-section__bullet">
                                <span>
                                    <svg
                                        width="32px"
                                        height="32px"
                                        viewBox="0 0 18 18"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g stroke="none" fill="none">
                                            <g transform="translate(-103.000000, -4323.000000)">
                                                <g transform="translate(100.000000, 4266.000000)">
                                                    <g transform="translate(0.000000, 54.000000)">
                                                        <g>
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path
                                                                // eslint-disable-next-line max-len
                                                                d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                                fill="#f4a444"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <h3 className="deep-learning-production-section__bullet__text">
                                    how to structure and develop
                                    production-ready machine learning code{' '}
                                </h3>
                            </div>
                            <div className="deep-learning-production-section__bullet">
                                <span>
                                    <svg
                                        width="32px"
                                        height="32px"
                                        viewBox="0 0 18 18"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g stroke="none" fill="none">
                                            <g transform="translate(-103.000000, -4323.000000)">
                                                <g transform="translate(100.000000, 4266.000000)">
                                                    <g transform="translate(0.000000, 54.000000)">
                                                        <g>
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path
                                                                // eslint-disable-next-line max-len
                                                                d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                                fill="#f4a444"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <h3 className="deep-learning-production-section__bullet__text">
                                    how to develop efficient and scalable data
                                    pipelines
                                </h3>
                            </div>
                            <div className="deep-learning-production-section__bullet">
                                <span>
                                    <svg
                                        width="32px"
                                        height="32px"
                                        viewBox="0 0 18 18"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g stroke="none" fill="none">
                                            <g transform="translate(-103.000000, -4323.000000)">
                                                <g transform="translate(100.000000, 4266.000000)">
                                                    <g transform="translate(0.000000, 54.000000)">
                                                        <g>
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path
                                                                // eslint-disable-next-line max-len
                                                                d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                                fill="#f4a444"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <h3 className="deep-learning-production-section__bullet__text">
                                    how to optimize the model’s performance and
                                    memory requirements
                                </h3>
                            </div>
                            <div className="deep-learning-production-section__bullet">
                                <span>
                                    <svg
                                        width="32px"
                                        height="32px"
                                        viewBox="0 0 18 18"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g stroke="none" fill="none">
                                            <g transform="translate(-103.000000, -4323.000000)">
                                                <g transform="translate(100.000000, 4266.000000)">
                                                    <g transform="translate(0.000000, 54.000000)">
                                                        <g>
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path
                                                                // eslint-disable-next-line max-len
                                                                d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                                fill="#f4a444"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <h3 className="deep-learning-production-section__bullet__text">
                                    how to make it available to the public by
                                    setting up a service on the cloud
                                </h3>
                            </div>
                            <div className="deep-learning-production-section__bullet">
                                <span>
                                    <svg
                                        width="32px"
                                        height="32px"
                                        viewBox="0 0 18 18"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g stroke="none" fill="none">
                                            <g transform="translate(-103.000000, -4323.000000)">
                                                <g transform="translate(100.000000, 4266.000000)">
                                                    <g transform="translate(0.000000, 54.000000)">
                                                        <g>
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path
                                                                // eslint-disable-next-line max-len
                                                                d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                                fill="#f4a444"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <h3 className="deep-learning-production-section__bullet__text">
                                    how to scale and maintain the service as the
                                    user base grows
                                </h3>
                            </div>
                        </Block>
                    </Group>
                    <h2>Is this book for you?</h2>
                    <Group size={3}>
                        <Card
                            className="deep-learning-production__card"
                            direction={Direction.horizontal}
                        >
                            <p className="deep-learning-production__card__text">
                                Are you a Deep Learning researcher with no
                                software experience?
                            </p>
                        </Card>
                        <Card
                            className="deep-learning-production__card"
                            direction={Direction.horizontal}
                        >
                            <p className="deep-learning-production__card__text">
                                Are you a software engineer who is starting out
                                with Machine Learning?
                            </p>
                        </Card>
                        <Card
                            className="deep-learning-production__card"
                            direction={Direction.horizontal}
                        >
                            <p className="deep-learning-production__card__text">
                                Do you want to become a Machine Learning
                                Engineer?
                            </p>
                        </Card>
                    </Group>
                    <p>
                        If you are interested in learning how to take a simple
                        model and transform it into a real-world application,
                        then you came to the right place. Whether you are a
                        software engineer, an ML researcher, a Data Scientist or
                        an ML enginner, I'm sure that you will find value in
                        this book.
                    </p>

                    <h2>Have a look at a free sample</h2>

                    <div className="deep-learning-production-pdf-view">
                        <object
                            data={DLProdSamplePdf}
                            type="application/pdf"
                            width="100%"
                            height="900px"
                        >
                            <i>
                                Your browser does not support embed PDFs.
                                Download the free sample from{' '}
                                <a href="https://theaisummer.com/static/DLProd-Sample-cfcffa77c61fe8e22ce13ee8a3839980.pdf">
                                    here
                                </a>
                                .
                            </i>
                        </object>
                    </div>

                    <div className="deep-learning-book-toc">
                        <h2>Table of Contents</h2>

                        <div className="deep-learning-book-toc__chapter">
                            Preface{' '}
                            <div className="deep-learning-book-toc__number">
                                xi
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__chapter">
                            Acknowledgements{' '}
                            <div className="deep-learning-book-toc__number">
                                xii
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__chapter">
                            1 About this Book{' '}
                            <div className="deep-learning-book-toc__number">
                                13
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__chapter">
                            2 Designing a Machine Learning System{' '}
                            <div className="deep-learning-book-toc__number">
                                5
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            2.1 Machine learning: phase zero{' '}
                            <div className="deep-learning-book-toc__number">
                                5
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            2.2 Data engineering{' '}
                            <div className="deep-learning-book-toc__number">
                                7
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            2.3 Model engineering{' '}
                            <div className="deep-learning-book-toc__number">
                                8
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            2.4 DevOps engineering{' '}
                            <div className="deep-learning-book-toc__number">
                                8
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            2.5 Putting it all together{' '}
                            <div className="deep-learning-book-toc__number">
                                9
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            2.6 Tackling a real-life problem{' '}
                            <div className="deep-learning-book-toc__number">
                                9
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__chapter">
                            3 Setting up a Deep Learning Workstation{' '}
                            <div className="deep-learning-book-toc__number">
                                13
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            3.1 Laptop setup{' '}
                            <div className="deep-learning-book-toc__number">
                                14
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            3.2 Frameworks and libraries{' '}
                            <div className="deep-learning-book-toc__number">
                                14
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            3.3 Development tools{' '}
                            <div className="deep-learning-book-toc__number">
                                15
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            3.4 Python package and environment management{' '}
                            <div className="deep-learning-book-toc__number">
                                17
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__chapter">
                            4 Writing and Structuring Deep Learning Code{' '}
                            <div className="deep-learning-book-toc__number">
                                21
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            4.1 Best practices{' '}
                            <div className="deep-learning-book-toc__number">
                                21
                            </div>
                        </div>

                        <div className="deep-learning-book-toc__subchapter">
                            4.2 Unit testing{' '}
                            <div className="deep-learning-book-toc__number">
                                34
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            4.3 Debugging{' '}
                            <div className="deep-learning-book-toc__number">
                                43
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            5 Data Processing{' '}
                            <div className="deep-learning-book-toc__number">
                                55
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            5.1 ETL: Extract, Transform, Load{' '}
                            <div className="deep-learning-book-toc__number">
                                56
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            5.2 Data reading{' '}
                            <div className="deep-learning-book-toc__number">
                                57
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            5.3 Processing{' '}
                            <div className="deep-learning-book-toc__number">
                                60
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            5.4 Loading{' '}
                            <div className="deep-learning-book-toc__number">
                                63
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            5.5 Optimizing a data pipeline{' '}
                            <div className="deep-learning-book-toc__number">
                                65
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            6 Training{' '}
                            <div className="deep-learning-book-toc__number">
                                71
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            6.1 Building a trainer{' '}
                            <div className="deep-learning-book-toc__number">
                                72
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            6.2 Training in the cloud{' '}
                            <div className="deep-learning-book-toc__number">
                                79
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            6.3 Distributed training{' '}
                            <div className="deep-learning-book-toc__number">
                                88
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            7 Serving{' '}
                            <div className="deep-learning-book-toc__number">
                                101
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            7.1 Preparing the model{' '}
                            <div className="deep-learning-book-toc__number">
                                101
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            7.2 Creating a web application using Flask{' '}
                            <div className="deep-learning-book-toc__number">
                                104
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            7.3 Serving with uWSGI and Nginx{' '}
                            <div className="deep-learning-book-toc__number">
                                110
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            7.4 Serving with model servers{' '}
                            <div className="deep-learning-book-toc__number">
                                117
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            8 Deploying{' '}
                            <div className="deep-learning-book-toc__number">
                                123
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            8.1 Containerizing using Docker and Docker Compose
                            <div className="deep-learning-book-toc__number">
                                123
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            8.2 Deploying in a production environment{' '}
                            <div className="deep-learning-book-toc__number">
                                136
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            8.3 Continous Integration and Delivery (CI/CD){' '}
                            <div className="deep-learning-book-toc__number">
                                141
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            9 Scaling{' '}
                            <div className="deep-learning-book-toc__number">
                                145
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            9.1 A journey from 1 to millions of users{' '}
                            <div className="deep-learning-book-toc__number">
                                145
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            9.2 Growing with Kubernetes{' '}
                            <div className="deep-learning-book-toc__number">
                                159
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            Building an End-to-End Pipeline{' '}
                            <div className="deep-learning-book-toc__number">
                                175
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            10.1 MLOps{' '}
                            <div className="deep-learning-book-toc__number">
                                175
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            10.2 Building a pipeline using TFX{' '}
                            <div className="deep-learning-book-toc__number">
                                179
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            10.3 MLOps with Vertex AI and Google cloud{' '}
                            <div className="deep-learning-book-toc__number">
                                188
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__subchapter">
                            10.4 More end to end solutions{' '}
                            <div className="deep-learning-book-toc__number">
                                198
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            Where to Go from Here{' '}
                            <div className="deep-learning-book-toc__number">
                                201
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            Appendix{' '}
                            <div className="deep-learning-book-toc__number">
                                203
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            List of Figures{' '}
                            <div className="deep-learning-book-toc__number">
                                207
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            Index{' '}
                            <div className="deep-learning-book-toc__number">
                                208
                            </div>
                        </div>
                        <div className="deep-learning-book-toc__chapter">
                            About the Author{' '}
                            <div className="deep-learning-book-toc__number">
                                209
                            </div>
                        </div>
                    </div>

                    <div className="deep-learning-book-faq">
                        <h2>FAQ</h2>
                        <Accordion
                            items={data.deepLearningProductionYaml.faq}
                        />
                    </div>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const DeepLearningProductionQuery = graphql`
    {
        deepLearningProductionYaml {
            faq {
                question
                answer
            }
            image {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            image_amazon {
                childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                        src
                        srcSet
                        aspectRatio
                        sizes
                        base64
                    }
                }
            }
            image_leanpub {
                childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                        src
                        srcSet
                        aspectRatio
                        sizes
                        base64
                    }
                }
            }
        }
    }
`;

export default DeepLearningProduction;
